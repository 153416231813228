<template>
  <div>
    <!-- <!~~ <button @click="events">asdasd</button> ~~> -->
    <!-- <am-toolbar v-if="engine" :engine="engine" :items="items" /> -->
    <!-- <div ref="container"></div> -->
    <div id="rootYuque" class="ne-doc-major-editor"></div>
  </div>
</template>

<script>
// import Engine, { View } from "@aomao/engine";
// import AmToolbar, { ToolbarPlugin, ToolbarComponent } from "am-editor-toolbar-vue2"
// import Codeblock, { CodeBlockComponent } from 'am-editor-codeblock-vue2'
// import Bold from '@aomao/plugin-bold'
// import Heading from '@aomao/plugin-heading'
// import File, { FileComponent , FileUploader } from '@aomao/plugin-file'
// import backcolor from '@aomao/plugin-backcolor'
// import tasklist, { CheckboxComponent } from "@aomao/plugin-tasklist"
// import alignment from "@aomao/plugin-alignment"
// import Embed, { EmbedComponent } from '@aomao/plugin-embed'
import { uploadFileUrl } from "@/api/files";
export default {
  name: "HelloWorld",
  // components: {
  //   AmToolbar,
  // },
  data() {
    return {
      // engine: null,
      // html: '',
      // items: [
      //   ['collapse'],
      //   ['undo', 'redo', 'paintformat', 'removeformat'],
      //   ['heading', 'fontfamily', 'fontsize'],
      //   ['bold', 'italic', 'strikethrough', 'underline', 'moremark'],
      //   ['fontcolor', 'backcolor'],
      //   ['alignment'],
      //   ['unorderedlist', 'orderedlist', 'tasklist', 'indent', 'line-height'],
      //   ['link', 'quote', 'hr']
      // ]
    };
  },
  mounted() {
    window.onload = function () {
      const { createOpenEditor } = window.Doc;
      // 创建编辑器
      const editor = createOpenEditor(document.getElementById('rootYuque'), {
        input: {},
        image: {
          isCaptureImageURL() {
            return false;
          },
          // 配置上传接口,要返回一个promise对象
          createUploadPromise: async (request) => {
            const {type, data} = request;
            return new Promise((resolve, reject) => {
              const data_up = new FormData();
              data_up.append("files", data);
              data_up.append("configCode", "file_event_content_img");
              uploadFileUrl(data_up).then((urls) => {
                resolve({
                  url: urls[0].savePath
                })
              })
            })
          },
        },
      });
      // 设置内容
    	editor.setDocument('text/lake', '<p><span style="color: rgb(255, 111, 4),rgb(243, 48, 171)">欢迎来到yuque编辑器</span></p>');
      // 监听内容变动
      editor.on('contentchange', () => {
        console.info(editor.getDocument('text/lake'));
      });
    }
    // const view = new View(this.$refs.container, {
    //   cards:[
    //     ToolbarComponent,
    //     CodeBlockComponent,
    //     FileComponent
    //   ],
    //   plugins: [
    //     Heading,
    //     ToolbarPlugin,
    //     Codeblock,
    //     Bold,
    //     File,
    //     FileUploader,
    //     backcolor,
    //     tasklist
    //   ],
    // });
    // view.render(this.html);
    // document.querySelectorAll(".data-file").forEach(item => {
    //   console.log(item);
    //   item.addEventListener("click", (e) => {
    //     e.preventDefault();
    //   })
    // })
    // const engine = new Engine(
    //   this.$refs.container,
    //   {
    //     cards:[
    //       ToolbarComponent,
    //       CodeBlockComponent,
    //       FileComponent,
    //       CheckboxComponent,
    //       EmbedComponent
    //     ],
    //     plugins: [
    //       Heading,
    //       ToolbarPlugin,
    //       Codeblock,
    //       Bold,
    //       File,
    //       FileUploader,
    //       backcolor,
    //       tasklist,
    //       alignment,
    //       Embed
    //     ],
    //     config:{
    //       [ToolbarPlugin.pluginName]: {
    //         config: [
    //           {
    //             title: '分组标题',// 可选
    //             items: [
    //               'image-uploader',
    //               'codeblock',
    //               'table',
    //               'file-uploader',
    //               'video-uploader',
    //               'math',
    //               'status',
    //               {
    //                 name: 'embed',
    //                 icon: 'StepBackwardOutlined',
    //                 title: '提示文字',
    //               }
    //             ],
    //           },
    //         ],
    //       },
    //       [FileUploader.pluginName]: {
    //         action: "http://39.170.105.112:8090/file/urlUpload",
    //         crossOrigin: true,
    //         headers: {
    //           Authorization: `Bearer ${localStorage.getItem('Admin-Token')}`
    //         },
    //         withCredentials: true,
    //         name: "files",
    //         data: {
    //           configCode: "file_mail_banner"
    //         },
    //         parse: (res) => {
    //           return {
    //             result: true,
    //             data: {
    //               url: res[0].savePath,
    //               preview: res[0].savePath,
    //               download: res[0].savePath,
    //               status: 'done',
    //             }
    //           };
    //         }
    //       },
    //     }
    //   },
    // );
    // this.engine = engine;
    // console.log(engine, new ToolbarPlugin());
  },
  methods: {
    // events() {
    //   console.log(this.engine.setValue(this.html));
    // },
    // fuck(e) {
    //   console.log(e);
    // }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
/deep/ .data-file {
  cursor: pointer;
}
</style>
